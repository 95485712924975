/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { css } from 'react-emotion';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Flex, PaperBox } from '../components/Layout';
import PageWrapper from '../components/PageWrapper';
import colors from '../utils/colors';

import gooseberry from '../assets/images/gooseberry-black.png';


const imgStyle = css`
  border-radius: 5px;
`;

class Message extends React.Component {
  state = {
    message: null,
  };

  componentDidMount() {
    this.setState({message:
      {
        'email0': 'Email verification out of date',
        'email1': 'Email verified',
      }[(new URLSearchParams(location.search)).get('tell')] || 
      'Missing message'
    });
  };

  render() {
    return (
      <Flex justifyContent="center" align-items="center">
        <PaperBox
          width={[1, '80%', '60%']}
          m={['3.5rem auto', '5.5rem auto', '5.5rem auto']}
          px={[3, 3, 5]}
          py={[3, 3, 3]}
          textAlign="center"
        >
          <h1>Beacons</h1>
          <p> {this.state.message} </p>
          <p>
            <img
              src={gooseberry}
              alt="Sea Gooseberry"
              width="200"
            />
          </p>
        </PaperBox>
      </Flex>
    );
  }
};

export default ({ data }) => {
  const mapData = data.map.childImageSharp;

  return (
    <PageWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Message - Beacons</title>
      </Helmet>

      <BackgroundImage
        Tag="section"
        className={imgStyle}
        fluid={mapData.fluid}
        backgroundColor={`#FFFFFF`}
        padding="20px"
      >
        <Message></Message>
      </BackgroundImage>
    </PageWrapper>
  );
};

export const query = graphql`
query {
  map: file(relativePath: { eq: "map.jpeg" }) {
    childImageSharp {
      fluid(maxWidth: 1500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  allContentJson {
		edges {
			node {
				index {
					title
					aboutText
				}
      }
    }
  }
}
`;

